'use strict';

import featureDetection from './partials/featureDetection';
import jQuery from 'jquery';
const bodyScrollLock = require('body-scroll-lock');
import lax from 'lax.js';
import Xwiper from 'xwiper';
import 'bootstrap/js/src/collapse';

featureDetection();

jQuery(($) => {
  //
  // Navbar overlay
  //

  const $html = $('html');
  const $overlay = $('.overlay');
  const $toggler = $('.navbar-toggler');

  const showOverlay = () => {
    $html.addClass('overlay-shown');
    bodyScrollLock.disableBodyScroll($overlay[0]);
    $toggler.attr('aria-expanded', true);
  };

  const hideOverlay = () => {
    $html.removeClass('overlay-shown');
    bodyScrollLock.enableBodyScroll($overlay[0]);
    $toggler.attr('aria-expanded', false);
    $overlay.find(':focus').blur();
  };

  $toggler.click(function() {
    if ($html.is('.overlay-shown')) {
      hideOverlay();
    } else {
      showOverlay();
    }
  });

  // If currently invisible overlay gains keyboard focus, switch navbar state.
  $(document)
    .on('focusin', 'html:not(.overlay-shown) .overlay *', showOverlay)
    .on('focusout', 'html.overlay-shown .overlay *', hideOverlay);

  //
  // Parallax
  //

  lax.addPreset('backdrop', () => {
    return {
      'data-lax-translate-y': '(vh) -90, (-elh) 60',
    };
  });

  lax.setup({
    breakpoints: {small: 0, large: 768},
  });

  //
  // Handle repainting efficiently.
  //

  let docWidth = window.innerWidth;
  let docHeight = document.body.scrollHeight;

  const widthChangeHandlers = [];
  const heightChangeHandlers = [lax.updateElements];

  const repaint = () => {
    if (docHeight != document.body.scrollHeight) {
      heightChangeHandlers.forEach((val) => val());
      docHeight = document.body.scrollHeight;
    }

    if (docWidth != window.innerWidth) {
      widthChangeHandlers.forEach((val) => val());
      docWidth = window.innerWidth;
    }

    lax.update(window.scrollY);

    window.requestAnimationFrame(repaint);
  };

  window.requestAnimationFrame(repaint);

  //
  // Carousels
  //

  $('.carousel').each((index, el) => {
    const $carousel = $(el);
    const $slideWrap = $carousel.find('.slides');
    const $slidesNext = $carousel.find('.slides-next');
    const $slides = $slideWrap.find('.slide');
    const $prev = $carousel.find('.btn-prev');
    const $next = $carousel.find('.btn-next');

    // Fired on all slide changes.
    const update = (activeIndex, $active) => {
      if (!$active) {
        $active = $carousel.find('.slide-active');
        activeIndex = $slides.index($active);
      }

      // Move slide container.
      const newLeft = activeIndex === 0 ? '0' :
        $slidesNext.offset().left - $active.offset().left -
        $active[0].offsetWidth;
      $slideWrap.css({
        transform: `translateX(${newLeft}px)`,
      });

      // Update counter.
      $carousel.find('.counter-current').text(activeIndex + 1);

      // Enable/disable navigation buttons.
      $prev.prop('disabled', activeIndex === 0);
      $next.prop('disabled', activeIndex === $slides.length - 1);
    };

    // Fired on next slide.
    const nextSlide = () => {
      let $active = $carousel.find('.slide-active');
      let activeIndex = $slides.index($active);

      // Do nothing if last slide.
      if (activeIndex === $slides.length - 1) {
        return;
      }

      $active.removeClass('slide-active');
      $active = $slides.eq(++activeIndex).addClass('slide-active');

      update(activeIndex, $active);
    };

    // Fired on previous slide.
    const prevSlide = () => {
      let $active = $carousel.find('.slide-active');
      let activeIndex = $slides.index($active);

      // Do nothing if first slide.
      if (activeIndex === 0) {
        return;
      }

      $active.removeClass('slide-active');
      $active = $slides.eq(--activeIndex).addClass('slide-active');

      update(activeIndex, $active);
    };

    // Attach event handlers strictly to enabled buttons so we can use handler
    // triggers for swipe without extra logic.
    $carousel
      .on('click', '.btn-next:enabled', nextSlide)
      .on('click', '.btn-prev:enabled', prevSlide);

    // Add support for swipe on touch devices.
    const xwiper = new Xwiper(el);
    xwiper.onSwipeLeft(() => $next.click());
    xwiper.onSwipeRight(() => $prev.click());

    // Make sure window width changes respond to this carousel.
    widthChangeHandlers.push(update);
  });

  //
  // Form handling
  //

  $('.needs-validation').on('submit', (e) => {
    const $form = $(e.target);

    if (!e.target.checkValidity()) {
      $form.addClass('was-validated');
      return false;
    }

    $form
      .removeClass('was-validated form-success form-error')
      .addClass('form-busy')
      .find('[type="submit"]')
      .prop('disabled', true);

    // Post form to AJAX.
    $.post($form.attr('action'), $form.serialize())
      .always((data) => {
        if ('error' === data.status) {
          $form.addClass('form-error')
            .find('.msg-error p.text-danger')
            .text(data.message);
        } else {
          $form.addClass('form-success');
          e.target.reset();
        }

        $form
          .removeClass('form-busy')
          .find('[type="submit"]')
          .prop('disabled', false);
      });

    return false;
  });
});
